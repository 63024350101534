<template>
  <SeeMoreInner>
    <h4>What does CBD do?</h4>
    <p>
      Increasingly countries worldwide are researching the benefits of CBD.
      Fortunately – and as a retailer of supplements – we operate in a heavily
      regulated industry that leaves no room for misleading promises. Part of
      the legislation we abide by means that we're only able to make health
      claims for products that have been approved within the EU register.
    </p>
    <p>
      Being an ingredient that's new to the UK, CBD is yet to be approved, so we
      cannot relay details to the public about relevant scientific research. We
      do – however – encourage you to go online and research for yourself.
      There's plenty of information out there that explains why CBD has gained
      such huge traction in the last few months.
    </p>
    <h4>Healthspan's CBD</h4>
    <p>
      As the number one direct vitamin and supplement provider in the UK, we
      take the safety and efficacy of our products very seriously. This is
      unchanged when it comes to the origin of our CBD product ingredients. Our
      CBD is sourced from highly regulated industrial hemp fields in Europe,
      with each batch being tested stringently and consequently awarded a
      certificate of analysis showing the contained levels of CBD and (trace)
      levels of THC. Each of these certificates can be downloaded from our very
      own website.
    </p>
    <p>
      To clarify, 'trace' levels of THC means the level of THC contained is too
      little to cause any psychoactive effects. The amount of THC that would
      cause the latter varies per person due to factors such as body mass. But
      as a rough guide, 1mg of THC per 1kg of bodyweight would cause
      psychoactive effects – meaning an average 60kg person would have to
      consume 60mg (0.060g) of THC to experience said effects. Each of our CBD
      oil capsules contains 0.000001g of THC and we recommend you take one to
      three capsules daily - hence 'trace levels of THC'.
    </p>
    <p>
      Available in high-strength 6.4mg and super-strength 15mg capsules, and
      2.75% and 5% concentration CBD oil droppers, Healthspan CBD Oil is
      extracted from EU hemp using non-toxic CO2. It's also fully decarboxylated
      and then purified, meaning the finished product is a high-quality oil that
      is batch tested to ensure high levels of CBD, zero impurities and only
      trace levels of THC. We've also added olive oil and hemp oil to our High
      Strength CBD capsules to optimise absorption into the body.
    </p>
    <p>
      For even quicker absorption, our CBD droppers are directly applied to the
      tongue and contain a hint of peppermint flavouring to mask the yucky taste
      of hemp. Each one of our CBD products is 100% natural, legal and safe to
      take.
    </p>
    <h4>Safety</h4>
    <p>
      CBD is extremely well tolerated. The reported side effects include dry
      mouth, low blood pressure, light headedness and drowsiness but these
      consequences are reported rarely. A new report from the World Health
      Organisation (WHO) on CBD actually suggests cannabis oil to be mostly
      well-tolerated, with a good safety profile.
    </p>
    <h4>Dosage</h4>
    <p>
      Dr Sarah Brewer says: 'Multiple small studies of CBD safety in humans
      suggest it's well tolerated across a wide dosage range. No significant
      central nervous system side effects, or effects on vital signs or mood
      were seen at doses of 200mg to 300 mg/day by mouth (some suggest 600mg to
      1,500mg per day may be tolerable). However, the Cannabis Trades
      Association UK suggests that CBD products with a daily adult dosage of up
      to 200mg should continue to be marketed as a food supplement.
    </p>
    <p>
      <strong>Do not use CBD during pregnancy or breastfeeding</strong>
    </p>
  </SeeMoreInner>
</template>

<script>
import SeeMoreInner from "@/components/SeeMoreInner.vue";
import pageTrans from "@/mixins/pagetrans";

export default {
  mixins: [pageTrans],
  components: {
    SeeMoreInner,
  },
  methods: {},
};
</script>
